.icon-country-flag,
.icon-country-flag-00,
.icon-country-flag-wld {
  background: svg-load('country-flag-00.svg') center center / 100% 100% no-repeat;
}

.icon-country-flag-au {
  background-image: svg-load('country-flag-au.svg');
}

.icon-country-flag-btc {
  background-image: svg-load('country-flag-btc.svg');
}

.icon-country-flag-copper {
  background-image: svg-load('country-flag-copper.svg');
}

.icon-country-flag-ca {
  background-image: svg-load('country-flag-ca.svg');
}

.icon-country-flag-ch {
  background-image: svg-load('country-flag-ch.svg');
}

.icon-country-flag-cn {
  background-image: svg-load('country-flag-cn.svg');
}

.icon-country-flag-eu,
.icon-country-flag-al,
.icon-country-flag-ad,
.icon-country-flag-at,
.icon-country-flag-by,
.icon-country-flag-be,
.icon-country-flag-ba,
.icon-country-flag-bg,
.icon-country-flag-hr,
.icon-country-flag-cy,
.icon-country-flag-cz,
.icon-country-flag-dk,
.icon-country-flag-ee,
.icon-country-flag-fo,
.icon-country-flag-fi,
.icon-country-flag-fr,
.icon-country-flag-de,
.icon-country-flag-gi,
.icon-country-flag-gr,
.icon-country-flag-hu,
.icon-country-flag-is,
.icon-country-flag-ie,
.icon-country-flag-im,
.icon-country-flag-it,
.icon-country-flag-rs,
.icon-country-flag-lv,
.icon-country-flag-li,
.icon-country-flag-lt,
.icon-country-flag-lu,
.icon-country-flag-mk,
.icon-country-flag-mt,
.icon-country-flag-md,
.icon-country-flag-mc,
.icon-country-flag-me,
.icon-country-flag-nl,
.icon-country-flag-no,
.icon-country-flag-pl,
.icon-country-flag-pt,
.icon-country-flag-ro,
.icon-country-flag-ru,
.icon-country-flag-sm,
.icon-country-flag-rs,
.icon-country-flag-sk,
.icon-country-flag-si,
.icon-country-flag-es,
.icon-country-flag-se,
.icon-country-flag-ua,
.icon-country-flag-va {
  background-image: svg-load('country-flag-eu.svg');
}

.icon-country-flag-gb {
  background-image: svg-load('country-flag-gb.svg');
}

.icon-country-flag-jp {
  background-image: svg-load('country-flag-jp.svg');
}

.icon-country-flag-nz {
  background-image: svg-load('country-flag-nz.svg');
}

.icon-country-flag-us {
  background-image: svg-load('country-flag-us.svg');
}

.icon-country-flag-xau {
  background-image: svg-load('country-flag-xau.svg');
}
